<script setup lang="ts">
import { notify } from "@kyvg/vue3-notification"
import {ref, onMounted, onBeforeMount, computed, watch} from "vue"
import {toggleClass, notifyError, notifyInfo, isDev, isIPhone} from "@/common2"
import {apiFetch, imageProxy, MJJobType2Readable, MJSubJobType2Readable, duration2Readable, time2ReadableShort, writeClipboard, getPage} from "../../common"
import MobileModal from "../../comps/MobileModal.vue"
import PromptTip from "../PromptTip.vue"
import {foldIn, foldOut, slideDownIn, slideDownOut} from "@/animation"
import Pager from "../Pager.vue"
import Barn from "barn"
import {useAccount, usePreference} from "@/store"
import {useWebPush} from "@/store"
import InputText from "@/comps/form/InputText.vue"
import {normalize, ParamDefaults} from "@/prompt-parser"
import type {NormalizeOptions, RefImage} from "@/prompt-parser"
import ImagePrompt from "./aimage/ImagePrompt.vue"
import bus from "@/bus"
import {storeToRefs} from "pinia"
import {refImageLeave, refImageEnter} from "@/animation"

const props = defineProps<{
    shouldShowSideBar: boolean
}>()


const prompt = ref("")
const mjmodel = ref("mj")
const page = ref(1)
const mjImages = ref(<any>[])
const mjInfo = ref(<any>{})
const preference = ref(<any>{})

const Pref = usePreference()

const error = ref("")

const isShowPromptTip = ref(false)
const isShowDescribe = ref(false)
const isShowImagePrompt = ref(false)
const isShowAdvanced = ref(false)
const keyOfRefImageShowing = ref("")


const isLoading = ref(false)
const isAdding = ref(false)
const isRequesting = ref(false)
const count = ref(0)

const isSettingPush = ref(false)




// 是否正在切换到“开启订阅”
const pendingToOn = ref(false)
const pendingToOff = ref(false)

// 绘图参数
const paramRaw = ref(false)
const paramStylize = ref(100)
const paramWeird = ref(0)
const paramChaos = ref(0)
const paramAR = ref("1:1")

const paramBasicRefWeight = ref(0.25)
const paramCRefWeight = ref(100)
const paramSRefWeight = ref(100)

// 垫图相关
const refImages = ref(<{[key: string]: RefImage}>{})

const isConfirmDeleteRefImage = ref(false)

// 确认删除垫图
const removingRefImage = ref("")


const imageShowing = ref(<any>null)

var autoReloadTimer :number = 0
var autoReloadInterval :number = 3000
var limit :number = 10

const refImageShowing = computed(() :RefImage => {
    const k = keyOfRefImageShowing.value
    if (refImages.value[k]) {
        return refImages.value[k]
    } else {
        return <RefImage>{}
    }
})

// 是否在图片上显示图片编号
// 用户“第一次”进入时，显示图片编号
// 用户以后进入，不再显示图片编号
function shouldShowImageNo() :boolean {

    // 现在总是显示图片编号
    return true

    const store = new Barn(sessionStorage)

    let n = getShouldShowImageNoCounter()
    console.log("当前的 shouldShowImageNoCounter 是 ", n)

    if (n <= 1 || store.get("cb.forceShowImageNo")) {
        return true
    }

    return false
}

function copyPrompt(p :string) {
    p = p.replace(/([\s]+,)/g, ", ")
    writeClipboard(p)
    notify({type: "success", text: "提示词已复制到剪贴板"})
}

function getShouldShowImageNoCounter() :number {
    let store = new Barn(localStorage)
    let n = store.get("cb.shouldShowImageNoCounter")
    if (!n) {
        return 0
    } else {
        return parseInt(n)
    }
}

function incrShouldShowImageNoCounter() {
    let store = new Barn(localStorage)
    let key = "cb.shouldShowImageNoCounter"
    let n = store.get(key)
    if (!n) {
        n = 1
    } else {
        n++
    }
    store.set(key, n)
}

const isPushOn = computed(() => {
    return useWebPush().subscription ? true : false
})



watch(refImages, (newVal) => {
    const j = JSON.stringify(newVal)
    localStorage.setItem("AImage_refImages", j)
}, {deep: true})
watch(paramBasicRefWeight, newVal => {
    localStorage.setItem("AImage_paramBasicRefWeight", newVal.toString())
})



onBeforeMount(() => {
    // 恢复提示词和模型设置
    let mPrompt = localStorage.getItem("AImage_description")
    if (mPrompt) {
        prompt.value = mPrompt
    }

    let mModel = localStorage.getItem("MJImage_model")
    if ( mModel !== null) {
        mjmodel.value = mModel
    }

    
    // 恢复配置选项
    let isShowTip = localStorage.getItem("MJImage_isShowPromptTip")
    if ( isShowTip !== null) {
        isShowPromptTip.value = isShowTip == "1"
    }

    const mShowAdvanced = localStorage.getItem("MJImage_isShowAdvanced")
    if (mShowAdvanced !== null) {
        isShowAdvanced.value = mShowAdvanced == "1"
    }

    // 恢复垫图
    const jbuf = localStorage.getItem("AImage_refImages")
    if (jbuf) {
        try {
            const j = JSON.parse(jbuf)
            for (let k in j) {
                refImages.value[k] = j[k]
            }
        } catch(e) {
            console.warn("解析 AImage_refImages 数据失败，原始数据是", jbuf)
        }
    }
    
    const iw = localStorage.getItem("AImage_paramBasicRefWeight")
    if (iw) {
        try {
            const iwNum = parseFloat(iw)
            if (!isNaN(iwNum)) {
                paramBasicRefWeight.value = iwNum
            }
        } catch(e) {
            console.warn("解析 AImage_paramBasicRefWeight 数据失败，原始数据是", iw)
        }
    }
})

var wsTriggerReloadTimer = 0
onMounted(() => { 
    bus.on("ws.model.mjimage.update", (e :any) => {
        clearTimeout(wsTriggerReloadTimer)
        wsTriggerReloadTimer = setTimeout(loadList, 500)
    })

    loadList()
    // loadInfo()
    loadPreference()
    Pref.ensure()
})

const downloadFilename = computed(() => {
    const promptRaw = imageShowing.value?.Prompt
    if (!promptRaw) {
        return "chatboy_aimage.png"
    }

    const urlFilename = imageShowing.value?.ImageURL.split("/").pop()
    if (!urlFilename) {
        return "chatboy_aimage.png"
    }

    let prompt = promptRaw
    prompt = prompt.replaceAll(" ", "_")
    prompt = prompt.replaceAll("　", "_")
    prompt = prompt.replaceAll(",", "-")
    prompt = prompt.replaceAll("，", "-")
    prompt = prompt.replaceAll(/[^一-鿿a-z0-9_-]/gi, "")

    let output = "chatboy-" + prompt + "-" + urlFilename
    if (output.length > 250) {
        output = output.slice(-250)
    }

    return output

})


function showImage(c :any) {
    imageShowing.value = c

    if (getShouldShowImageNoCounter() == 0) {
        (new Barn(sessionStorage)).set("cb.forceShowImageNo", true)
        console.log("这是第一次显示图片，强制将 cb.forceShowImageNo 设置为 true")
    }

    incrShouldShowImageNoCounter()
}
function tryShowImage(c :any) {
    if (c.SuccessTime <= 0) {
        console.debug("图片的 SuccessTime 小于等于 0，不予显示此图片", c)
        return
    }

    if (c?.ImageURL != '' && c.SuccessTime > 0) {
        showImage(c)
    }
}

async function loadPreference() {
    if (!useAccount().isLoggedIn) {
        console.debug("尚未登录，不会请求接口数据")
        return
    }

    try {
        let j = await apiFetch("/goapi/preference/read", {})
        preference.value = j.Preference
    } catch (e) {
        notify({
            type: "error",
            text: "无法加载配置信息: " + (e as Error).message
        })
    } 
}

async function loadInfo() {
    try {
        let j = await apiFetch("/goapi/mjimage/info", {})
        mjInfo.value = j.info
    } catch (e) {
        notify({
            type: "error",
            text: "读取绘画信息失败: " + (e as Error).message
        })
    } 
}

const shouldShowBusy = computed(() :boolean => {
    const cnt = mjInfo.value?.ActiveJobCount || 0
    if (cnt > 3) {
        return true
    } else {
        return false
    }
})


function addPreset(label :string) {
    console.log("添加一个 add 事件", label)

    // 检查当前的提示词中是否已经有这个 label，如果没有则添加
    let isExists = false
    let s :string = prompt.value
    s = s.split("，").join(",")
    s = s.split("　").join(" ")
    s = s.split(" --").join(", --")
    let tokens = s.split(",")
    tokens.forEach(v => {
        let w = v.trim()
        w = w.toLowerCase()
        if (w == label.toLowerCase()) {
            isExists = true
        }
    })

    if (isExists == false ){
        let s = prompt.value
        s = s.trim()
        if (s.slice(-1) != "," && s.slice(-1) != "，" && s.length > 0) {
            s += ", "
        }
        s += label
        prompt.value = s
    }
}


function removePreset(label :string) {
    console.log("发生一个 remove 事件", label)

    // 在现在的 prompt 中删除指定的内容
    let s :string = prompt.value
    s = s.split("，").join(",")
    s = s.split("　").join(" ")
    s = s.split(" --").join(", --")
    let tokens = s.split(",")
    tokens = tokens.filter(v => {
        let w = v.trim()
        w = w.toLowerCase()
        if (w == label.toLowerCase()) {
            return false
        }
        return true
    })
    prompt.value = tokens.join(",")
}


watch(prompt, (newVal) => {
    // console.log("描述变成了: ", newVal)
    localStorage.setItem("AImage_description", newVal)
})
watch(mjmodel, (newVal) => {
    localStorage.setItem("MJImage_model", newVal)
})
watch(isShowPromptTip, (newVal) => {
    localStorage.setItem("MJImage_isShowPromptTip", newVal ? "1" : "0")
})
watch(isShowAdvanced, (newVal) => {
    localStorage.setItem("MJImage_isShowAdvanced", newVal ? "1" : "0")
})


const totalPage = computed(() => {
    return Math.ceil(count.value / limit)
})


// 是否显示免费配额剩余数量
const isShowFreeQuota = computed(() :boolean => {
    if (!mjInfo.value) {
        return false
    }

    // 仅向未订阅套餐，或套餐过期的用户显示
    if (mjInfo.value?.CurrentPlanName == "") {
        return true
    }
    if (mjInfo.value?.IsQuotaAvailable == false || mjInfo.value?.CurrentPlanName == '') {
        return true
    }

    return false
})

// // 是否显示订阅内配额剩余数量
// const isShowPlanQuota = computed(() :boolean => {
//     if (!mjInfo.value) {
//         return false
//     }

//     // 仅向订阅了套餐的用户显示
//     if (mjInfo.value?.CurrentPlanName != "" ){
//         return true
//     }
    
//     return false
// })


async function loadList() {
    if (!useAccount().isLoggedIn) {
        console.debug("尚未登录，不会请求接口数据")
        return
    }

    clearTimeout(autoReloadTimer)

    isLoading.value = true
    error.value = ""


    try {
        let j = await apiFetch(`/goapi/mjimage/list?limit=${limit}&page=` + getPage(), {})
        mjImages.value = j.mjimages
        count.value = j.count

        autoReloadInterval = 20 * 1000

    } catch (e) {
        error.value = (e as Error).message
        notify({
            type: "error",
            text: (e as Error).message
        })
        autoReloadInterval += 1000
        autoReloadInterval = Math.min(autoReloadInterval,  30 * 1000)
            
    } finally {
        isLoading.value = false

        // 如果列表中尚有未完成的任务，且当前位于首页，则自动刷新列表
        for (let i in mjImages.value) {
            let img = mjImages.value[i]
            if (img.ImageURL == '' && img.FailTime == 0) {
                // 如果存在未取消的任务，且任务未完成
                if (page.value == 1) {
                    // 且当前位于首页，则自动刷新
                    autoReloadTimer = setTimeout(loadList, autoReloadInterval)
                    break
                }
            }
        }

        loadInfo()
    }
}


async function cancel(id: number) {
    try {
        isRequesting.value = true
        let j = await apiFetch(`/goapi/mjimage/cancel?id=${id}`, {
            "method": "post",
        })
        
        loadList()
    } catch (e) {
        notify({
            "type": "error",
            text: (e as Error).message,
        })
    } finally {
        isRequesting.value = false
    }
}



async function add() {

    if (prompt.value.trim() == "") {
        return
    }

    if (isAdding.value == true) {
        console.debug("上一个任务尚在提交，拒绝操作")
        return
    }


    try {
        isAdding.value = true

        let j = await apiFetch("/goapi/mjimage/create", {
            method: "post",
            body: JSON.stringify({
                input_prompt: prompt.value,
                prompt: promptNormalized.value,
                mjmodel: mjmodel.value,
            })
        })

        notify({
            type: "success",
            text: "绘画任务添加成功，请等待绘画完成",
        })



        if (Pref.p?.MJImageKeepPrompt == false) {
            prompt.value = ""
        }
        if (Pref.p?.MJImageKeepRefImage == false) {
            refImages.value = {}
        }

        loadList()
    } catch (e) {
        notify({
            type: "error",
            text: (e as Error).message,
        })
    } finally {
        isAdding.value = false
    }
}


// 重绘
async function redraw(image :any) {
    console.log("尝试重绘", image)

    let redrawPrompt :string = image.InputedPrompt || ""
    redrawPrompt = redrawPrompt.trim()
    if (redrawPrompt == "") {
        redrawPrompt = image.Prompt
    }
    redrawPrompt = redrawPrompt.trim()
    if (redrawPrompt == "") {
        console.warn("此图片提示词为空，无法重绘")
        return 
    }


    // 这里动一点小手脚，先把当前用户输入的提示词保存起来，我们处理好要重绘的提示词之后，再恢复用户输入的提示词
    const originPrompt = prompt.value
    prompt.value = redrawPrompt
    const _promptNormalized = promptNormalized.value
    prompt.value = originPrompt


    try {
        imageShowing.value = null
        isAdding.value = true

        let j = await apiFetch("/goapi/mjimage/create", {
            method: "post",
            body: JSON.stringify({
                input_prompt: redrawPrompt,
                prompt: _promptNormalized,
                mjmodel: image.MJModel,
            })
        })

        notify({
            type: "success",
            text: "绘画任务添加成功，请等待绘画完成",
        })

        prompt.value = ""

        loadList()
    } catch (e) {
        notify({
            type: "error",
            text: (e as Error).message,
        })
    } finally {
        isAdding.value = false
    }
}



async function upsample(id: number, no: number) {
    try {
        isRequesting.value = true
        let j = await apiFetch(`/goapi/mjimage/upsample`, {
            "method": "post",
            body: JSON.stringify({
                ID: id,
                No: no,
            })
        })
        
        imageShowing.value = null
        loadList()
    } catch (e) {
        notify({
            "type": "error",
            text: (e as Error).message,
        })
    } finally {
        isRequesting.value = false
    }
}

async function variation(id: number, no: number, subJobType: string = "") {
    try {
        isRequesting.value = true
        let j = await apiFetch(`/goapi/mjimage/variation`, {
            "method": "post",
            body: JSON.stringify({
                ID: id,
                No: no,
                SubJobType: subJobType,
            })
        })
        
        imageShowing.value = null
        loadList()
    } catch (e) {
        notify({
            "type": "error",
            text: (e as Error).message,
        })
    } finally {
        isRequesting.value = false
    }
}


async function outpaint(id: number, scale: string) {
    try {
        isRequesting.value = true
        let j = await apiFetch(`/goapi/mjimage/outpaint`, {
            "method": "post",
            body: JSON.stringify({
                ID: id,
                Scale: scale,
            })
        })
        
        imageShowing.value = null
        loadList()
    } catch (e) {
        notify({
            "type": "error",
            text: (e as Error).message,
        })
    } finally {
        isRequesting.value = false
    }
}


async function upscale(id: number, subJobType: "creative" | "subtle") {
    try {
        isRequesting.value = true
        let j = await apiFetch(`/goapi/mjimage/upscale`, {
            "method": "post",
            body: JSON.stringify({
                ID: id,
                // Scale: scale,
                SubJobType: subJobType,
            })
        })
        
        imageShowing.value = null
        loadList()
    } catch (e) {
        notify({
            "type": "error",
            text: (e as Error).message,
        })
    } finally {
        isRequesting.value = false
    }
}

function clearImageShowing() {
    imageShowing.value = null
}


async function switchPush() {
    // 1. 首先将开关置为目标
    let switchTo = !isPushOn.value

    console.debug("用户想要将推送状态设置为", switchTo)

    try {
        isSettingPush.value = true
        if (switchTo) {
            // 用户想要打开推送
            await useWebPush().register()
        } else {
            // 用户想要关闭推送
            await useWebPush().unregister({reason: "用户手动关闭", all: true})
        }
    } catch(e) {
        notifyError("绘图提醒开关设置失败: " + (e as Error).message)
    } finally {
        isSettingPush.value = false
    }
}

function resetParams() {
    paramRaw.value = false
    paramStylize.value = ParamDefaults.stylize as number
    paramWeird.value = ParamDefaults.weird as number
    paramChaos.value = ParamDefaults.chaos as number
    paramAR.value = ParamDefaults.ar as string
}

// Raw 选项当前是否有效
const isParamRawAvailable = computed(() => {
    if (mjmodel.value == "niji" || mjmodel.value == "niji6") {
        return false
    } else {
        return true
    }
})

// 当前用户设置的选项是否都为默认设置
const isParamInDefault = computed(() => {
    if (paramStylize.value != ParamDefaults.stylize) {
        return false
    }
    if (paramWeird.value != ParamDefaults.weird) {
        return false
    }
    if (paramChaos.value != ParamDefaults.chaos) {
        return false
    }
    if (paramRaw.value != false) {
        return false
    }

    return true
})
    
function min(a: number, b :number) :number {
    if (a < b) {
        return a
    } else {
        return b
    }
}

const promptNormalized = computed(() :string => {
    paramBasicRefWeight.value = min(paramBasicRefWeight.value, maxBasicRefWeight.value)

    let opts :NormalizeOptions = {
        stylize: paramStylize.value,
        weird: paramWeird.value,
        chaos: paramChaos.value,
        cRefWeight: paramCRefWeight.value,
        sRefWeight: paramSRefWeight.value,
        basicRefWeight: paramBasicRefWeight.value,
    }
    if (paramRaw.value && isParamRawAvailable.value) {
        opts.style = "raw"
    }
    if (paramAR.value != ParamDefaults.ar) {
        opts.ar = paramAR.value
    }

    opts.refImages = Object.values(refImages.value)
    

    const ret = normalize(prompt.value, opts)
    // console.log("输入参数和结果是", opts, ret)

    return ret
})

// 成功上传垫图
function onImagePromptSuccess(res :any) {
    // 手动给 ShortURL 后面加上 .jpg 扩展名
    // iPhone 在上传 HEIC 图片前，会在本地将图片转换成 JPEG 格式，但其具体参数似乎有一点少见
    // MJ 在某次更新后，无法再正常识别此类 JPEG 图片，因此我们在服务端做了一次 jpeg 转换，保证 MJ 能正常识别
    
    const shortURL = res.ShortURL + ".jpg"
    const key = res.HashSHA224
    console.log("上传成功", shortURL, res)

    refImages.value[key] = {
        key: key,
        url: shortURL,
        isBasicRef: true,
        isCRef: false,
        isSRef: false,
    }
}

function onImagePromptSuccess_OLD(res :any) {   
    if (!res.ShortURL) {
        console.warn("上传数据中没有 ShortURL 字段", res)
        return 
    }

    // 手动给 ShortURL 后面加上 .jpg 扩展名
    // iPhone 在上传 HEIC 图片前，会在本地将图片转换成 JPEG 格式，但其具体参数似乎有一点少见
    // MJ 在某次更新后，无法再正常识别此类 JPEG 图片，因此我们在服务端做了一次 jpeg 转换，保证 MJ 能正常识别
    const shortURL = res.ShortURL + ".jpg"

    // 如果现有提示词已经有这个图片了，则不会继续添加
    if (prompt.value.indexOf(shortURL) >= 0) {
        notifyInfo("这张图片已经添过了")
        return
    }

    prompt.value = shortURL + " " + prompt.value    
}

const maxBasicRefWeight = computed(() => {
    if (mjmodel.value == "mj6" || mjmodel.value == "niji6") {
        return 3
    } else {
        return 2
    }
    
})


const isConfigSaving = ref(false)
async function saveConfig(name :string, value :string) {
    console.log("请求保存配置", name, value)

    try {
        isConfigSaving.value = true

        let j = await apiFetch("/goapi/preference/update", {
            method: "post",
            body: JSON.stringify({
                Name: name,
                Value: value,
            })
        })

        notify({
            type: "success",
            text: "配置已保存",
        })


        // 在刷新配置信息前先手动设置一下配置信息
        if (name == "mj_image_keep_prompt") {
            Pref.p.MJImageKeepPrompt = (value == "1")
        } else if (name == "mj_image_keep_ref_image") {
            Pref.p.MJImageKeepRefImage = (value == "1")
        }
    } catch (e) {
        notify({
            type: "error",
            text: (e as Error).message,
        })
    } finally {
        Pref.reload()
        isConfigSaving.value = false
    }
}

</script>

<template>

    <div class="split-ctrl split-ctrl-aimage">

        <div class="split-tile">

            <div class="title-section">
                <div class="left-part">

                    <div class="ctrl">
                        <router-link to="/tools" class="button-g-icon button-size-44 button-fill-nude button-back">                    
                            <icon name="Arrow-Left" />
                        </router-link>
                        <div class="title-box">
                            <h2 class="title">Ai 绘画</h2>
                        </div>
                    </div>
                    <div class="sub">
                        <p class="g-label-info tip-tx-left-count" v-if="!isShowFreeQuota">剩余可绘次数 {{mjInfo.PlanRemains}}</p>
                        <p class="g-label-info" v-if="isShowFreeQuota">剩余免费绘画次数 {{mjInfo.FreeRemains}}</p>
                        <p class="g-label-warn" v-if="preference?.MJImageSpeed == 'fast'">FAST模式</p>
                    </div>

                </div>
                <div class="right-part">
                    <icon name="Loading-Circle" class="loading-stat" v-if="isLoading" />

                    <router-link to="/help?t=qa#qa-aidraw-tip" class="button-g-icon button-size-36 button-fill-nude">                    
                        <icon name="Chat-Bubble-Question" />
                    </router-link>
                    <!-- 暂时没用 
                    <a href="" class="button-g-icon button-size-44 button-fill-nude">
                        <icon name="More-Dot" />
                    </a>
                    -->
                    
                </div>
            </div>

            <!-- 按钮：创建绘画 -->
            <div class="act-section">
                <div class="section-ctrl">
                    <div class="button-g-3d button-medium" :class="{'button-loading': isAdding}" @click="add">
                        <div class="ctrl">创建绘画</div>
                    </div>
                    <p class="tip-tx-left-count" v-if="!isShowFreeQuota">剩余可绘次数 {{mjInfo.PlanRemains}}</p>
                </div>
            </div>

            <div class="content-section content-section-ctrl">

                <!-- <div class="ctrl-tile">
                    <p class="row-title">选择绘画模型</p>
                    <div class="form-input-ctrl">
                        <select name="" id="">
                            <option value="mj">Midjourney</option>
                            <option value="niji">Stable Diffusion</option>                        
                        </select>
                        <div class="ctrl">
                            <icon name="Arrow-Down" />            
                        </div>
                    </div>
                </div> -->

                <div class="ctrl-tile row">
                    <!--<p class="row-title">选择绘画风格</p>-->
                    <div class="form-input-ctrl flex-a">
                        <div class="icon-ctrl">
                            <icon name="Palette" />
                        </div>
                        <select name="" v-model="mjmodel">
                            <optgroup label="默认风格">
                                <option value="mj6">默认风格 v6</option>
                                <option value="mj">默认风格 v5.2</option>
                            </optgroup>
                            <optgroup label="动漫风格">    
                                <option value="niji6">动漫风格 v6</option>
                                <option value="niji">动漫风格 v5</option>
                            </optgroup>
                            
                        </select>
                        <div class="ctrl">
                            <icon name="Arrow-Down" />            
                        </div>
                    </div>
                    <div class="form-input-ctrl flex-b">
                        <div class="icon-ctrl">
                            <icon name="Aspect-Ratio" />
                        </div>
                        <select v-model="paramAR">
                            <option value="1:1">1:1 正方形</option>
                            <optgroup label="竖图">    
                                <option value="9:16">9:16 手机壁纸</option>
                                <option value="3:4">3:4 社媒竖图</option>
                                <option value="4:5">4:5 肖像</option>
                            </optgroup>
                            <optgroup label="横图">    
                                <option value="4:3">4:3 公众号图</option>
                                <option value="5:4">5:4 风景照</option>
                                <option value="16:9">16:9 宽屏</option>
                                <option value="21:9">21:9 超宽带屏</option>
                            </optgroup>
                        </select>
                        <div class="ctrl">
                            <icon name="Arrow-Down" />            
                        </div>
                    </div>
                </div>

                <!-- 图片描述 -->
                <div class="ctrl-tile">
                    <!--<p class="row-title">输入图片描述</p>-->
                    <div class="form-input-ctrl">
                                                
                        <textarea name="" id=""
                            placeholder="请输入描述画面的英语单词，以空格分隔" v-model="prompt"
                            @keypress.ctrl.enter="add"
                        ></textarea>

                        <!--
                        <div v-if="isDev()">调试：{{promptNormalized}}</div>
                        -->
                        
                        <div class="sub-ctrl">
                        

                            <!-- 垫图区域 -->
                            <div class="btn-ctrl">

                                <!-- 按钮：上传图片 -->
                                <ImagePrompt v-slot="s" @success="onImagePromptSuccess">
                                    <div class="img-button" @click="s.upload">
                                        <icon name="Image-Plus-Add" class="trigger-add" />
                                    </div>
                                    <div class="img-button loading" v-if="s.isUploading">
                                        <icon name="Loading-Circle" />
                                        <!--上传中-->
                                        {{(s.progress * 100).toFixed(0)}}%
                                    </div>
                                </ImagePrompt>

                                <!-- 垫图列表 -->
                                <div class="thumbnail-box">
                                    <div class="overflow-inner">
                                        <!-- <TransitionGroup @leave="refImageLeave" @enter="refImageEnter"> -->
                                        <TransitionGroup>
                                        <div class="img-button" 
                                            v-for="img in refImages" :key="img.key"
                                        >
                                            <!-- <div class="trigger-delete" @click="removingRefImage = img.key" > -->
                                            <div class="trigger-delete" @click="() => delete refImages[img.key]" >
                                                <icon name="Cross-Close-Thick3px" />
                                            </div>
                                            <div class="trigger-csi"  @click="keyOfRefImageShowing=img.key">
                                                <icon name="User-Thick2px" v-if="img.isCRef" />
                                                <icon name="Paperclip-Thick2px" v-if="img.isSRef" />
                                                <icon name="Photo-Art-Thick2px" v-if="img.isBasicRef" />
                                            </div>
                                            <img :src="img.url" alt="" class="img-cover" @click="keyOfRefImageShowing=img.key">
                                        </div>
                                        </TransitionGroup>
                                    </div>
                                </div>


                                <!--
                                <ImagePrompt v-slot="s" @success="onImagePromptSuccess">
                                    <div class="button-g" v-if="!s.isUploading" @click="s.upload">
                                        <icon name="Image-Plus-Add" />
                                        垫图
                                    </div>
                                    <div class="button-g button-loading" v-if="s.isUploading">
                                        <icon name="Loading-Circle" />
                                        垫图({{(s.progress * 100).toFixed(0)}}%)
                                    </div>
                                </ImagePrompt>
                                -->
                                <!--
                                <div class="button-g button-small"  @click="isShowDescribe=true" v-if="isDev()">
                                    <icon name="Feather-Pen-Edit" />
                                    述图
                                </div>
                                -->
                                
                            </div>
                            
                            <!--暂时隐藏这个垫图样式区域
                            <div class="img-thumbnail">
                                <div class="tile">
                                    <div class="ctrl-ux">
                                        <icon name="Cross-Close" />
                                    </div>
                                    <img src="/demo/test.png" alt="" class="img-tile">
                                </div>
                                <div class="tile">
                                    <div class="ctrl-ux">
                                        <icon name="Cross-Close" />
                                    </div>
                                    <img src="/demo/test.png" alt="" class="img-tile">
                                </div>
                            </div>
                            -->

                        </div>
                    </div>
                </div>

                <!-- 繁忙提示 -->
                <Transition name="slideDown5em">
                <div class="g-info-label g-label-warn font13" v-if="shouldShowBusy">
                    <icon name="Speaker-Sound" />
                    <p>当前绘画人数较多，绘画时间可能会延长</p>
                </div>
                </Transition>

                <Transition name="slideDown5em">
                <div class="g-info-label g-label-warn font13" v-if="paramRaw && !isParamRawAvailable">
                    <icon name="Speaker-Sound" />
                    <p>当前风格不支持 Raw 选项</p>
                </div>
                </Transition>

                <!-- 调试用 -->
                <div class="ctrl-tile" v-if="isDev()">
                    <p class="row-title">调试用：最终计算出的提示词</p>
                    <div>{{promptNormalized}}</div>
                </div>

                <!-- Ai绘画成功通知设置 -->
                <div class="ctrl-tile">
                    <div class="width100 flex-row-left flex-gap10">
                        <p class="row-title">画图完成时通知我</p>
                        
                        <div class="row-link-tile link-text-nude">
                            <div class="form-switch-ctrl" :class="{'active': isPushOn, 'loading': isSettingPush}" @click="switchPush"></div>
                        </div>
                    </div>
                </div>

                <!-- 高级设置 -->
                <div class="ctrl-tile">
                    <div class="width100 flex-row-between">
                        <p class="row-title hint-dot" :class="{'active': !isParamInDefault}">
                            高级设置
                        </p>
                        <div class="row-link-tile link-text-nude" @click="isShowAdvanced = !isShowAdvanced">
                            展开
                            <icon name="Arrow-Down" />            
                        </div>
                    </div>

                    <Transition @enter="slideDownIn" @leave="slideDownOut">
                    <div class="settings-helper-assets" v-if="isShowAdvanced">

                        <div class="sec-lr-space sec-gapline" :class="{'disabled': !isParamRawAvailable}">
                            <div class="tile-part">
                                <div class="flex-col-left flex-gap4">
                                    <p class="weight500">开启 Raw 模式</p>
                                    <p class="font13 color-black60">--style raw 开启后，生成的 4 图风格会更加统一</p>
                                </div>
                            </div>
                            <div class="tile-part">
                                <div class="form-switch-ctrl" :class="{'active': paramRaw}" @click="paramRaw = !paramRaw"></div>
                            </div>
                        </div>    
                        
                        <div class="sec-lr-space sec-gapline">
                            <div class="tile-part">
                                <div class="flex-col-left flex-gap4">
                                    <p class="weight500">风格化</p>
                                    <p class="font13 color-black60">--stylize 值越高，生成图像可能很艺术但与描述词关联弱 (0-1000)</p>
                                </div>
                            </div>
                            <div class="tile-part">
                                <InputText v-model="paramStylize" :step="100" :max="1000" :min="0" />
                            </div>
                        </div>   

                        <div class="sec-lr-space sec-gapline">
                            <div class="tile-part">
                                <div class="flex-col-left flex-gap4">
                                    <p class="weight500">怪异度</p>
                                    <p class="font13 color-black60">--weird 值越高，构图和风格更加怪诞 (0-3000)</p>
                                </div>
                            </div>
                            <div class="tile-part">
                                <InputText v-model="paramWeird" :step="100" :max="3000" :min="0" />
                            </div>
                        </div>   

                        <div class="sec-lr-space sec-gapline">
                            <div class="tile-part">
                                <div class="flex-col-left flex-gap4">
                                    <p class="weight500">变化强度</p>
                                    <p class="font13 color-black60">--chaos 值越高，越容易出现风格和构图的多样性 (0-100)</p>
                                </div>
                            </div>
                            <div class="tile-part">
                                <InputText v-model="paramChaos" :step="10" :max="100" :min="0" />
                            </div>
                        </div>   

                        <div class="sec-lr-space sec-gapline">
                            <div class="tile-part">
                                <div class="flex-col-left flex-gap4">
                                    <p class="weight500">保留提示词</p>
                                    <p class="font13 color-black60">绘图后，保留已经输入的提示词</p>
                                </div>
                            </div>
                            <div class="tile-part">
                                <div class="form-switch-ctrl" 
                                    :class="{'active': Pref.p?.MJImageKeepPrompt, 'loading': isConfigSaving}"
                                     @click="e => saveConfig('mj_image_keep_prompt', Pref.p?.MJImageKeepPrompt ? '0' : '1')"
                                ></div>
                            </div>
                        </div>   

                        <div class="sec-lr-space sec-gapline">
                            <div class="tile-part">
                                <div class="flex-col-left flex-gap4">
                                    <p class="weight500">保留垫图</p>
                                    <p class="font13 color-black60">绘图后，保留已经上传的垫图图片</p>
                                </div>
                            </div>
                            <div class="tile-part">
                                <div class="form-switch-ctrl" 
                                    :class="{'active': Pref.p?.MJImageKeepRefImage, 'loading': isConfigSaving}"
                                     @click="e => saveConfig('mj_image_keep_ref_image', Pref.p?.MJImageKeepRefImage ? '0' : '1')"
                                ></div>
                            </div>
                        </div>   

                        <div class="sec-lr-space sec-gapline">
                            <div class="tile-part">
                                <div class="button-g button-small button-border-black" @click="resetParams">
                                    <icon name="Refresh-Reset" />
                                    恢复默认
                                </div>
                            </div>
                        </div>  
                    </div>
                    </Transition>
                </div>


                
                <!-- 提示词助手 -->
                <div class="ctrl-tile">
                    <div class="width100 flex-row-between">
                        <p class="row-title">描述词助手</p>
                        <div class="row-link-tile link-text-nude" @click="isShowPromptTip = true" v-if="isShowPromptTip == false">
                            展开
                            <icon name="Arrow-Down" />            
                        </div>
                        <div class="row-link-tile link-text-nude" @click="isShowPromptTip = false"  v-if="isShowPromptTip == true">
                            收起
                            <icon name="Arrow-Up" />            
                        </div>
                    </div>
                    <Transition @enter="slideDownIn" @leave="slideDownOut">
                        <div v-if="isShowPromptTip" class="width100 margin-bottom-20">
                        <Component :is="PromptTip" :prompt="prompt" @add="addPreset" @remove="removePreset" @close="isShowPromptTip=false"  />
                        </div>
                    </Transition>
                </div>

            </div>
            

        </div>


        <!-- 绘画列表 -->
        <!-- 绘画列表：尚无绘画-->
        <div v-if="mjImages.length == 0 && !isLoading" class="split-tile split-history offset-shadow-bottom-inset">
            <div class="empty-tip-box">
                <icon name="Brush" />
                <p class="tx">你的画廊空空如也 :(</p>
            </div>
        </div>
        <!-- 绘画列表：列表数据-->
        <div v-else class="split-tile split-history offset-shadow-bottom-inset">

            <Component :is="Pager" :limit="limit" :total="count" :showTotal="true" />


            <div class="history-box">

                <!--静态新增样式-->
                <!-- 列表区域：述图 -->
                <!-- <div class="history-tile">
                    <a class="info-img">
                        <div class="view">
                            <icon name="Search-Zoom" />            
                        </div>
                        <img src="/demo/test.png" alt="" class="img-tile">
                    </a>
                    <div class="info-a">
                        <p class="tx-a prompt cut-row3">
                            Test test test test test test test test test test test test test test test test
                        </p>
                        <p class="tx-b">
                            <span>述图</span>
                            <span>id26304</span>
                        </p>
                        <div class="tx-ctrl">
                            <p class="tx-b tx-b-time">
                                <span class="">2024/1/28 19:11:42</span>
                                <span class="">55秒</span>
                            </p>
                            <a class="ctrl-button snap-tip-ctrl">
                                <p class="snap-tip" title="复制提示词" style="--snap-tip-count: 5"></p>
                                <icon name="Copy" />            
                            </a>
                        </div>
                    </div>
                    <div class="info-b">  
                    </div>
                </div> -->
                <!--静态新增样式 --------END-->

                                    

                <div class="history-tile" v-for="c in mjImages">

                    <!-- 缩略图部分-->
                    <a class="info-img" @click.prevent="tryShowImage(c)">
                        <div class="view" v-if="c.ImageURL != ''">
                            <icon name="Search-Zoom" />            
                        </div>
                        <div class="info-placeholder" v-else-if="c.FailTime > 0">
                            <p>😵</p>
                        </div>
                        <div class="info-placeholder" v-else-if="c.InternalStatus == 'init'">
                            <!--  排队中 -->
                            <p class="loading">⏳</p>
                        </div>
                        <div class="info-placeholder" v-else-if="c.InternalStatus == 'translated'">
                            <!--  排队中 -->
                            <p class="loading">⏳</p>
                        </div>
                        <div class="info-placeholder" v-else>
                            <p class="loading">⏳</p>
                        </div>

                        <img :src="imageProxy(c.ImageURL) + '?maxwh=512'" alt="" class="loading-bg">
                    </a>


                    <div class="info-a">
                        <!-- 提示词 -->
                        <!-- 通过 .cut-row3 的 class 来控制行数显示 -->
                        <p class="tx-a prompt cut-row3" @click="toggleClass($event, 'cut-row3')">
                            <!-- {{c.Prompt.slice(0, 400)}}<span v-if="c.Prompt.length > 400">...</span> -->
                            {{c.Prompt.replace(/([\s]+,)/g, ",")}}
                        </p>

                        <p class="tx-b" v-if="c.LastError!=''">错误: {{c.LastError}}</p>

                        <!-- FAST 模式？-->
                        <p class="tx-b">
                            <span>
                                {{MJJobType2Readable(c.JobType, c.SubJobType)}}
                                <span v-if="c.SubJobType != ''">({{MJSubJobType2Readable(c.JobType, c.SubJobType)}})</span>
                            </span>
                            <span>id{{c.ID}}</span>
                            <span class="label-tip g-label-warn" v-if="c.MJIsFast">FAST模式</span>
                        </p>

                        <!-- 耗时、复制提示词-->
                        <div class="tx-ctrl">
                            <p class="tx-b tx-b-time">
                                <span class="">{{time2ReadableShort(c.CreateTime)}}</span>
                                <!-- <span class="" v-if="c.SuccessTime > 0">耗时: {{duration2Readable(c.SuccessTime - c.CreateTime)}}</span> -->
                                <span class="" v-if="c.SuccessTime > 0">{{duration2Readable(c.SuccessTime - c.CreateTime)}}</span>
                            </p>
                            <a class="ctrl-button snap-tip-ctrl" @click="copyPrompt(c.Prompt)">
                                <p class="snap-tip" title="复制提示词" style="--snap-tip-count: 5"></p>
                                <icon name="Copy" />            
                            </a>
                            <!--                             
                            <a href="" class="ctrl-button snap-tip-ctrl">
                                <p class="snap-tip" title="复制图片" style="--snap-tip-count: 4"></p>
                                <icon name="Copy-Image" />            
                            </a> 
                            -->

                        </div>
                    </div>

                    <!-- 取消/查看 -->
                    <div class="info-b">
                        <a  class="link-text-nude-line color-orange"
                            @click="cancel(c.ID)" v-if="c.ImageURL == '' && c.FailTime == 0 && (new Date()).getTime() / 1000 - c.CreateTime > 60" 
                        >取消</a>

                        <!-- 新版本不需要“查看”按钮，直接点击小图查看
                        <span @click="showImage(c)" v-if="c.ImageURL != '' || c.PreviewImageURL != ''" className="font-link">查看</span>
                        -->

                        <div class="info-b" v-else-if="c.FailTime > 0">
                            <p class="tx-info">失败</p>
                        </div>
                        <div class="info-b" v-else-if="c.InternalStatus == 'init'">
                            <p class="tx-info">排队中</p>
                        </div>
                        <div class="info-b" v-else-if="c.InternalStatus == 'translated'">
                            <p class="tx-info">排队中</p>
                        </div>
                        <!--                         
                        <div class="info-b" v-else>
                            <p class="tx-info">正在绘画</p>
                        </div> -->

                    </div>
                </div>

            </div>


            <!-- 移动版才显示的 Pager -->
            <Component :is="Pager" :limit="limit" :total="count" :showTotal="true" class="pager-mobile" />


        </div>

    </div>

    <Transition name="modal">
        <Component :is="MobileModal" :isShow="imageShowing ? true : false" @close="clearImageShowing" :hideOKButton="true" :title="MJJobType2Readable(imageShowing?.JobType)" class="popup popup-draw">

            <div class="img-box">
                <div class="img-info">

                    <a :href="imageProxy(imageShowing.ImageURL || imageShowing.PreviewImageURL)" target="mjimage_preview">

                        <!--Only shows when 4 images tile-->
                        <div class="img-mark" v-if="shouldShowImageNo() && ['imagine', 'variation'].indexOf(imageShowing.JobType) >= 0">
                            <span>1</span>
                            <span>2</span>
                            <span>3</span>
                            <span>4</span>
                        </div>

                        <img
                            :src="imageProxy(imageShowing.ImageURL || imageShowing.PreviewImageURL)" 
                            class="mj-img loading-bg"
                        />
                    </a>
                    <!--
                    <p className="tx">{{imageShowing.Prompt}}</p>
                    -->
                </div>

                <div class="ctrl-button-group" v-if="!isRequesting && ['imagine', 'variation', 'outpaint'].indexOf(imageShowing.JobType) >= 0">
                    <button v-for="i in 4" @click.prevent="upsample(imageShowing.ID, i)" :disabled="isRequesting" class="button-g-3d button-less-rounded"><div class="ctrl">U{{i}}</div></button>
                </div>
                <div class="ctrl-button-group" v-if="!isRequesting && ['imagine', 'variation', 'outpaint'].indexOf(imageShowing.JobType) >= 0">
                    <button v-for="i in 4" @click.prevent="variation(imageShowing.ID, i)" :disabled="isRequesting" class="button-g-3d button-less-rounded sp"><div class="ctrl">V{{i}}</div></button>
                </div>
                <div class="ctrl-button-group" v-if="!isRequesting && ['upsample'].indexOf(imageShowing.JobType) >= 0">
                    <button @click.prevent="variation(imageShowing.ID, 0, 'low_variation')" :disabled="isRequesting" class="button-g-3d button-less-rounded sp"><div class="ctrl">V (轻微)</div></button>
                    <button @click.prevent="variation(imageShowing.ID, 0, 'high_variation')" :disabled="isRequesting" class="button-g-3d button-less-rounded sp"><div class="ctrl">V (强烈)</div></button>
                </div>

                
                <div class="ctrl-button-group">
                    <div class="flex flex-gap10" v-if="!isRequesting && ['upsample'].indexOf(imageShowing.JobType) >= 0">
                        <button @click.prevent="outpaint(imageShowing.ID, '2')" :disabled="isRequesting" class="button-g-3d button-less-rounded sp"><div class="ctrl">画卷扩阔 2倍</div></button>
                        <button @click.prevent="outpaint(imageShowing.ID, '1.5')" :disabled="isRequesting" class="button-g-3d button-less-rounded sp"><div class="ctrl">画卷扩阔 1.5倍</div></button>
                    </div>
                    <div v-if="imageShowing.MJModel != 'mj6'">
                    <div class="flex flex-gap10" v-if="!isRequesting && ['upsample'].indexOf(imageShowing.JobType) >= 0">
                        <button @click.prevent="upscale(imageShowing.ID, 'subtle')" :disabled="isRequesting" class="button-g-3d button-less-rounded sp"><div class="ctrl">会心精描 (稳定)</div></button>
                        <button @click.prevent="upscale(imageShowing.ID, 'creative')" :disabled="isRequesting" class="button-g-3d button-less-rounded sp"><div class="ctrl">会心精描 (创意)</div></button>
                    </div>
                    </div>
                </div>
                
                <div class="ctrl-button-group">
                    <a
                        :href="imageShowing.ImageURL" :download="downloadFilename"
                        class="button-g-3d button-less-rounded" 
                        v-if="!isIPhone()"
                    >
                        <div class="ctrl">
                            <icon name="Download" />
                            下载图片
                        </div>
                    </a>
                    <a  class="button-g-3d button-less-rounded">
                        <div class="ctrl" @click="redraw(imageShowing)">
                            <icon name="Refresh-Double-Reset" />
                            重绘
                        </div>
                    </a>
                </div>


                <span v-if="isRequesting" class="font14">正在处理...</span>

                <div class="info">
                    <div class="tx">
                        <p v-if="['imagine', 'upsample'].indexOf(imageShowing.JobType) >= 0">※ U表示放大某张图，V表示变化某张图</p>
                        <p v-if="['upsample'].indexOf(imageShowing.JobType) >= 0">※ 会心精描：提高图片分辨率</p>
                        
                        <p v-if="isIPhone()">※ 点击查看大图，并【长按】保存图片</p>

                        <div class="margin-top-8 flex-row-center flex-gap8">
                            <router-link to="/help?t=qa#qa-aidraw-tip">
                            <div class="tip-question-button">
                                <icon name="Chat-Bubble-Question" />
                                绘画帮助?
                            </div>
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>

        </Component>
    </Transition>


    <!-- 述图弹窗 -->
    <Transition name="modal">
        <Component :is="MobileModal" :isShow="isShowDescribe" @close="isShowDescribe=false" :hideOKButton="true" title="述图" icon="Feather-Pen-Edit" class="popup-draw">
            <div class="img-box">
                <div class="img-info">
                    <a>
                        <img
                            src="/demo/test.png" 
                            class="mj-img loading-bg desc-img"
                        />
                    </a>
                </div>
                <div class="desc margin-bottom-16">
                    <div class="desc-tile">
                        <p class="g-label-info">1</p>
                        <p class="">a cat sleeping in his vehicle seat, in the style of corporate punk, wealthy portraiture, marcin sobas, elegant , light orange and light crimson, hinchel or, elegantly formal --ar 85:78</p>
                    </div>
                    <div class="desc-tile">
                        <p class="g-label-info">2</p>
                        <p class="">a cat is sleeping in a car with an orange coat, in the style of corporate punk, elegantly formal, stanley pinker --ar 85:78</p>
                    </div>
                    <div class="desc-tile">
                        <p class="g-label-info">3</p>
                        <p class="">the cat is sleeping in the back seat, in the style of corporate punk, elegant , crimson and amber, streetwise style, elegantly formal, sfumato, low depth of field --ar 85:78</p>
                    </div>
                    <div class="desc-tile">
                        <p class="g-label-info">4</p>
                        <p class="">some on a cat, in the style of corporate punk, orton effect, dreamlike atmosphere, canon eos 5d mark iv, elegantly formal, auto body works, calming --ar 85:78</p>
                    </div>
                </div>
                <div class="ctrl-button-group">
                    <a class="button-g-3d button-less-rounded">
                        <div class="ctrl">
                            画 1
                        </div>
                    </a>
                    <a class="button-g-3d button-less-rounded">
                        <div class="ctrl">
                            画 2
                        </div>
                    </a>
                    <a class="button-g-3d button-less-rounded">
                        <div class="ctrl">
                            画 3
                        </div>
                    </a>
                    <a class="button-g-3d button-less-rounded">
                        <div class="ctrl">
                            画 4
                        </div>
                    </a>
                </div>
                <div class="info">
                    <div class="tx">
                        <div class="margin-top-8 flex-row-center flex-gap8">
                            <router-link to="/help?t=qa#qa-aidraw-tip">
                            <div class="tip-question-button">
                                <icon name="Chat-Bubble-Question" />
                                绘画帮助?
                            </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

        </Component>
    </Transition>


    <!-- 垫图弹窗 -->
    <Transition name="modal">
    <Component :is="MobileModal" :isShow="keyOfRefImageShowing != ''" @close="keyOfRefImageShowing = ''" :hideOKButton="true" title="垫图设置" icon="Photo-Art" class="popup-draw">
        <div class="img-box">
            <div class="img-info">
                <img
                    :src="refImageShowing.url" 
                    class="mj-img loading-bg desc-img"
                />
            </div>
            <div class="prompt-sec margin-bottom-16">
                <div class="prompt-sec-card"  :class="{'disable' : !refImageShowing.isCRef}">
                    <div class="prompt-sec-tile">
                        <div class="flex flex-gap8 padding-bottom-8">
                            <!-- <input type="checkbox" name="img-prompt" id="radio-cref" :checked="refImageShowing.isCRef" @click="refImages[keyOfRefImageShowing].isCRef = false"> -->
                            <input type="checkbox" name="img-prompt" id="radio-cref"
                                :checked="refImageShowing.isCRef"
                                @change="e => refImages[keyOfRefImageShowing].isCRef = (e.target as any).checked"
                            >
                            <label for="radio-cref">参考人物角色</label>
                        </div>
                    </div>
                    <div class="prompt-sec-tile">
                        <div class="flex-col-left flex-gap8">
                            <p class="g-label-info">--cref [url]</p>
                            <p class="font13 color-black60">
                                --cw 参数越低越关注角色脸部特征，越高越关注角色的头发服饰等
                                <span class="color-purple weight500">[0-100]</span>
                            </p>
                        </div>
                        <InputText v-model="paramCRefWeight" :step="1" :max="100" :min="0" class="input-small input-parameter" />
                    </div>
                </div>

                <div class="prompt-sec-card"  :class="{'disable' : !refImageShowing.isSRef}">
                    <div class="prompt-sec-tile">
                        <div class="flex flex-gap8 padding-bottom-8">
                            <input type="checkbox" name="img-prompt" id="radio-sref"
                                :checked="refImageShowing.isSRef"
                                @change="e => refImages[keyOfRefImageShowing].isSRef = (e.target as any).checked"
                            >
                            <label for="radio-sref">参考作画风格</label>
                        </div>
                    </div>
                    <div class="prompt-sec-tile">
                        <div class="flex-col-left flex-gap8">
                            <p class="g-label-info">--sref [url]</p>
                            <p class="font13 color-black60">
                                --sw 参数越高垫图画面风格越强烈和相近
                                <span class="color-purple weight500">[0-1000]</span>
                            </p>
                        </div>
                        <InputText v-model="paramSRefWeight" :step="10" :max="1000" :min="0" class="input-small input-parameter" />
                    </div>
                </div>

                <div class="prompt-sec-card" :class="{'disable' : !refImageShowing.isBasicRef}">
                    <div class="prompt-sec-tile">
                        <div class="flex flex-gap8 padding-bottom-8">
                            <input type="checkbox" name="img-prompt" id="radio-ref"
                                :checked="refImageShowing.isBasicRef"
                                @change="e => refImages[keyOfRefImageShowing].isBasicRef = (e.target as any).checked"
                            >
                            <label for="radio-ref">一般垫图</label>
                        </div>
                    </div>
                    <div class="prompt-sec-tile">
                        <div class="flex-col-left flex-gap8">
                            <p class="g-label-info">[url]</p>
                            <p class="font13 color-black60">
                                --iw 参数越高越参考整体的画面
                                <span class="color-purple weight500">[0-2]</span>
                                ，v6 和 niji6 模型最高可设置到
                                <span class="color-purple weight500">[0-3]</span>
                            </p>
                        </div>
                        <InputText v-model="paramBasicRefWeight" :step="0.05" :max="maxBasicRefWeight" :min="0" class="input-small input-parameter" />
                    </div>
                </div>

            </div>


            <div class="ctrl-button-group flex-row-between important">
                <div class="tile">
                    <div class="del-chat-button" v-if="!isConfirmDeleteRefImage" @click="isConfirmDeleteRefImage = true">
                        <icon name="Trash-Can-Delete2" />
                        删除
                    </div>
                    <div class="flex flex-gap16" v-else>
                        <p class="font14">删除？</p>
                        <div class="flex flex-gap12">
                            <button class="button-g-icon button-size-30" @click="isConfirmDeleteRefImage = false">
                                <icon name="Cross-Close" />
                            </button>
                            <button class="button-g-icon button-size-30 button-fill-light-orange" @click="() => {delete refImages[keyOfRefImageShowing]; isConfirmDeleteRefImage = false; keyOfRefImageShowing = ''}">
                                <icon name="OK-Tick" />
                            </button>
                        </div>
                    </div>
                </div>
                <a class="button-g-3d width-120">
                    <div class="ctrl" @click="keyOfRefImageShowing=''">
                        保存设置
                    </div>
                </a>
            </div>

            <!--
            <div class="ctrl-button-group">
                <div class="tile">
                    <div class="del-chat-button" @click="isConfirmDeleteRefImage = true">
                        <icon name="Trash-Can-Delete2" />
                        删除
                    </div>
                    <div class="flex flex-gap16" :class="{'hidden' : !isConfirmDeleteRefImage}">
                        <p class="font14">删除？</p>
                        <div class="flex flex-gap12">
                            <button class="button-g-icon button-size-30" @click="isConfirmDeleteRefImage = false">
                                <icon name="Cross-Close" />
                            </button>
                            <button class="button-g-icon button-size-30 button-fill-light-orange" @click="() => {delete refImages[keyOfRefImageShowing]; isConfirmDeleteRefImage = false; keyOfRefImageShowing = ''}">
                                <icon name="OK-Tick" />
                            </button>
                        </div>
                    </div>
                </div>
                <a class="button-g-3d width-120">
                    <div class="ctrl" @click="keyOfRefImageShowing=''">
                        保存设置
                    </div>
                </a>
            </div>
            -->

        </div>
    </Component>
    </Transition>


    <!-- 清空对话内容，确认弹窗 -->
    <Component v-if="false" :is="MobileModal" :isShow="removingRefImage != ''" @close="removingRefImage = ''" icon="Clear-Broom" title="删除垫图">
        <div class="width100 flex-row-center padding-top-48 padding-bottom-48 radius20 bg-color-trans-black2 margin-bottom-20">
            <p class="font16">确认删除这个垫图么？</p>
        </div>
        <div class="flex-row-center flex-gap20 width100">
            <!-- 确认/取消 删除 -->
            <div class="button-g-3d button-medium" @click="removingRefImage = ''">
                <div class="ctrl">取消</div>
            </div>
            <div class="button-g-3d button-medium button-sp-purple" @click="() => {delete refImages[removingRefImage]; removingRefImage = '';}">
                <div class="ctrl">确认</div>
            </div>
        </div>
    </Component>



</template>

<style scoped>
.disabled {
    filter: graysacle(0.8);
    opacity: 0.5;
}
</style>
